import { type HTMLInputTypeAttribute, memo, useMemo } from "react";
import "./input.css";
import { FaRegCalendar } from "react-icons/fa";
import { FiEye, FiEyeOff, FiLock, FiSearch, FiUser } from "react-icons/fi";
import {
	formatNumberString,
	removeNumberFormatting,
} from "./format-number-string";
import { type InputType, types } from "./models/input-type";
import type { ViewProperties } from "./properties";

const InputView = memo((props: ViewProperties) => {
	const getIcon = (type?: InputType, isPeekIcon?: boolean) => {
		if (props.iconName === "FiUser") {
			return (
				<FiUser
					className={[
						props.iconSolid ? iconSolidStyle : iconOutlineStyle,
						props.iconClass,
					].join(" ")}
					color={props.iconColor}
					size="18px"
				/>
			);
		}

		switch (type ? type : props.type) {
			case "date": {
				return (
					<FaRegCalendar
						className={[iconSolidStyle, props.iconClass].join(" ")}
						color={props.iconColor}
						size="18px"
					/>
				);
			}
			case "password": {
				if (isPeekIcon) {
					return props.peekPassword ? (
						<FiEyeOff
							className={[iconOutlineStyle, props.iconClass, "mr-5"].join(" ")}
							color={props.iconColor}
							size="18px"
						/>
					) : (
						<FiEye
							className={[iconOutlineStyle, props.iconClass, "mr-5"].join(" ")}
							color={props.iconColor}
							size="18px"
						/>
					);
				}
				return (
					<FiLock
						className={[iconOutlineStyle, props.iconClass].join(" ")}
						color={props.iconColor}
						size="18px"
					/>
				);
			}
			case "search": {
				return (
					<FiSearch
						className={[iconOutlineStyle, props.iconClass].join(" ")}
						color={props.iconColor}
						size="18px"
					/>
				);
			}
			default: {
				return <></>;
			}
		}
	};

	const isDateType = props.type === "date";
	const isPasswordType = props.type === "password";
	const isThemed = props.theme !== "none";

	const getInputType = (): HTMLInputTypeAttribute => {
		if (
			props.type === "password" &&
			props.peekablePassword &&
			props.peekPassword === true
		) {
			return "text";
		}

		return types[props.type || "text"];
	};

	const getThemeClass = () => {
		if (props.theme) {
			switch (props.theme) {
				case "secondary": {
					return "input-outer-secondary";
				}
				default:
					return "input-outer-primary";
			}
		}
		return "input-outer-primary";
	};

	const getPattern = () => {
		switch (props.type) {
			case "number":
				return "[\\d]*";
			default:
				return undefined;
		}
	};

	const iconStyle = [
		"input-icon",
		isDateType
			? "ml-0.5 sm:ml-5"
			: isThemed ||
					(!isThemed &&
						((props.iconRight && !props.hideIcon) || props.peekablePassword))
				? "ml-5"
				: "",
		props.iconRight && "mr-5",
	].join(" ");

	const iconOutlineStyle = [iconStyle, "input-icon-outline"].join(" ");

	const iconSolidStyle = [iconStyle, "input-icon-solid"].join(" ");

	const inputInnerStyle = [
		"bg-transparent",
		"border-none",
		"outline-none",
		isThemed
			? [
					isDateType ? "ml-0.5 sm:ml-5" : "ml-5",
					"py-3",
					"font-primary-regular",
				].join(" ")
			: !props.iconRight && !props.hideIcon
				? "ml-5"
				: "",
		"input-inner",
		"w-full",
		props.value ? "" : "input-inner-empty",
		props.autoComplete === "off" ? "keeper-ignore" : "",
	].join(" ");

	const inputMiddleStyle = [
		"flex",
		"flex-row",
		"grow-0",
		"items-center",
		"min-w-0",
		"w-full",
		props.iconRight ? "flex-row-reverse" : "flex-row",
	].join(" ");

	const inputOuterStyle = [
		"min-w-0 flex flex-row grow-0 justify-between overflow-hidden shrink",
		isThemed &&
			[
				"items-center",
				"border",
				isDateType
					? "pr-0.5 sm:pr-5"
					: !props.peekablePassword && (!props.iconRight || props.hideIcon)
						? "pr-5"
						: "input-outer-delayed",
				"input-base",
				getThemeClass(),
			].join(" "),
		!props.valid && !props.overrideShowValidation && "input-outer-invalid",
		props.error && "input-outer-invalid",
		props.disabled && "opacity-40",
		props.className,
	].join(" ");

	const value = useMemo(() => {
		if (props.type === "currency") {
			return formatNumberString(props.value ?? "");
		}
		return props.value;
	}, [props.value, props.type]);

	return (
		<div className={inputOuterStyle}>
			<div className={inputMiddleStyle}>
				{!props.hideIcon ? getIcon() : undefined}
				<input
					className={inputInnerStyle}
					name={props.name}
					defaultValue={props.defaultValue}
					disabled={props.disabled}
					max={props.maxValue}
					autoComplete={props.autoComplete}
					maxLength={props.maxLength ? props.maxLength : undefined}
					min={props.minValue}
					minLength={props.minLength ? props.minLength : undefined}
					placeholder={props.placeholder}
					pattern={getPattern()}
					required={props.required}
					readOnly={props.readOnly}
					title={props.title}
					type={getInputType()}
					value={value}
					onBlur={props.onBlur}
					onChange={(e) => {
						let value = e.currentTarget.value;
						if (props.type === "currency") {
							value = removeNumberFormatting(value);
						}
						if (props.onChange) props.onChange(value);
					}}
					onKeyDown={(e) => {
						if (
							props.scrollDisabled &&
							(e.key === "ArrowUp" || e.key === "ArrowDown")
						)
							e.preventDefault();

						if (props.onEnter) {
							e.key === "Enter" && props.onEnter(e.currentTarget.value);
						}
					}}
					onFocus={props.onFocus}
				/>
			</div>
			<div className="py-2" onClick={props.onTogglePeekPassword}>
				{props.peekablePassword && isPasswordType
					? getIcon("password", true)
					: undefined}
			</div>
		</div>
	);
});

export { InputView };
