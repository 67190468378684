import { useConfirmPasswordReset, useUpdateUserPassword } from "@app/helpers";
import type { MappedReasons } from "@app/services";
import { useEffect, useState } from "react";
import { ChangePasswordModalView } from "./change-password-modal-view";
import type { FormValues } from "./models/form";
import type { State } from "./models/state";
import type { Properties } from "./properties";

export const ChangePasswordModal = (props: Properties) => {
	const [confirmPasswordReset] = useConfirmPasswordReset();

	const [updateUserPassword] = useUpdateUserPassword();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [state, setState] = useState<State>({
		errors: [],
		validate: false,
	});

	const onClearErrors = (fieldName: string) => {
		const newState = { ...state, errors: [] };

		if (newState?.mappedReasons?.[fieldName])
			newState.mappedReasons[fieldName] = undefined;

		setState(newState);
	};

	const onFormSubmit = (values: FormValues) => {
		if (props.resetPassword) {
			if (props.paramToken && props.paramUser) {
				setIsSubmitting(true);
				confirmPasswordReset(
					props.paramUser,
					props.paramToken,
					values.newPassword || "",
					(errors?: string[] | undefined, mappedReasons?: MappedReasons) => {
						setIsSubmitting(false);
						setState({
							...state,
							validate: true,
							mappedReasons,
						});

						if (!errors && !mappedReasons) {
							props.onClose(true);
						}
					},
				);
			}
		} else {
			setIsSubmitting(true);
			updateUserPassword(
				values.currentPassword || "",
				values.newPassword || "",
				(errors?: string[] | undefined, mappedReasons?: MappedReasons) => {
					setIsSubmitting(false);
					setState({
						...state,
						validate: true,
						mappedReasons,
					});

					if (!errors && !mappedReasons) {
						props.onClose(true);
					}
				},
			);
		}
	};

	const onValidate = (
		errors: FormValues,
		customError: boolean | undefined,
		submit: () => void,
	) => {
		if (customError || Object.keys(errors).length > 0) {
			setState({ ...state, validate: true });
			return;
		}

		submit();
	};

	useEffect(() => {
		if (state.errors !== props.errors)
			setState({ ...state, errors: props.errors });
	}, [props.errors]);

	useEffect(() => {
		if (!props.open) {
			setState({ ...state, validate: false });
		} else {
			setState({ ...state, mappedReasons: undefined });
		}
	}, [props.open]);

	return (
		<ChangePasswordModalView
			{...props}
			errors={state.errors}
			isSubmitting={isSubmitting}
			mappedReasons={state.mappedReasons}
			validate={state.validate}
			onClearErrors={onClearErrors}
			onFormSubmit={onFormSubmit}
			onValidate={onValidate}
		/>
	);
};
