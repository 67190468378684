import background from "@app/assets/images/background.jpg";
import { Wizard } from "@app/components/wizard";
import { links } from "@app/constants/links";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import React from "react";
import "./forgot-password.css";

export type FormValues = {
	username?: string;
};

export const ForgotPasswordView = React.memo(
	(props: {
		errors?: string[];
		formattedThrottling?: string;
		formSubmitted: boolean;
		step: number;
		success?: string;
		validate: boolean;
		isSubmitting: boolean;
		onClearError: () => void;
		onLogin: () => void;
		onResetPassword: (values: FormValues) => void;
		onValidate: (errors: FormValues, submit: () => void) => void;
	}) => {
		const resetButtonStyle = [
			"font-primary-bold",
			"py-5",
			"text-lg",
			"w-full",
			props.formattedThrottling ? "normal-case" : "uppercase",
		].join(" ");

		const formStep1 = (
			<div className="flex flex-col flex-1 lg:mt-4">
				<Formik
					initialValues={{ username: "" }}
					onSubmit={props.onResetPassword}
				>
					{({ handleSubmit, validateForm }) => (
						<>
							<div className="font-secondary-regular py-0 forgot-password-heading">
								Reset Password
							</div>
							<div className="my-6 rounded-full w-24 forgot-password-heading-separator" />
							<Transition
								show={props.errors !== undefined && props.errors.length > 0}
								enter="transition ease-out duration-300"
								leave="transition east-out duration-300"
								enterFrom="opacity-0 translate-y-5"
								enterTo="opacity-100 translate-y-0"
								leaveTo="opacity-0 -translate-y-5"
							>
								<div className="flex flex-col gap-y-2">
									<div
										className="font-primary-regular forgot-password-error"
										dangerouslySetInnerHTML={{
											__html: `<p>${props.errors?.join("</p><p>")}</p>`,
										}}
									/>
								</div>
							</Transition>
							<div className="flex flex-col gap-y-5 pt-3 w-full">
								<Input
									className="pl-5 py-2.5 forgot-password-input"
									name="username"
									iconClass="forgot-password-icon"
									iconLibrary="Feather"
									iconName="FiUser"
									placeholder="Email address"
									required
									title="Please enter your email address"
									type="text"
									useFormik
									validateOnChange={props.validate}
									onChange={props.onClearError}
									onEnter={async () =>
										props.onValidate(await validateForm(), handleSubmit)
									}
								/>
								<div
									className={`${
										props.formattedThrottling ? "-mt-1" : "mt-4"
									} w-full`}
								>
									<Transition
										show={props.formattedThrottling !== undefined}
										enter="transition ease-out duration-300"
										leave="transition east-out duration-300"
										enterFrom="opacity-0 translate-y-5"
										enterTo="opacity-100 translate-y-0"
										leaveTo="opacity-0 -translate-y-5"
									>
										<div className="flex flex-col gap-y-2">
											<div className="font-primary-regular forgot-password-error">
												Please wait before trying again
											</div>
										</div>
									</Transition>
									<Button
										className={[resetButtonStyle].join(" ")}
										disabled={
											props.formattedThrottling !== undefined ||
											props.isSubmitting
										}
										theme="primary"
										onClick={async () =>
											props.onValidate(await validateForm(), handleSubmit)
										}
									>
										<div className="select-none">
											{props.formattedThrottling ??
												(props.isSubmitting ? "SUBMITTING" : "SUBMIT")}
										</div>
									</Button>
								</div>
								<Button
									className="flex flex-row font-primary-regular mt-2 opacity-60 self-center lg:self-start text-lg forgot-password-login"
									onClick={props.onLogin}
								>
									Back to Login
								</Button>
							</div>
						</>
					)}
				</Formik>
			</div>
		);

		const formComplete = (
			<div className="flex flex-col flex-1 lg:mt-4 gap-y-4 items-center">
				<h2 className="font-secondary-regular py-0 forgot-password-heading text-center">
					Request sent
				</h2>
				<h3 className="font-secondary-regular mb-4 text-center forgot-password-subheading">
					A password reset link has been sent to the email address provided (if
					an associated account exists).
				</h3>
				<Button
					theme="primary"
					className="forgot-password-complete-login font-primary-bold mt-5 py-5 text-lg uppercase w-full text-center"
					onClick={props.onLogin}
				>
					Back to Login
				</Button>
			</div>
		);

		const getContactFormContent = () => (
			<div
				className={[
					"flex flex-col grow lg:overflow-y-auto w-full",
					"lg:h-full",
				].join(" ")}
			>
				<div
					className="bg-cover bg-center flex flex-col justify-center items-center h-full px-6 py-24 lg:py-0 forgot-password-background"
					style={{ backgroundImage: `url(${background})` }}
				>
					<div className="font-secondary-regular -mb-1 text-3xl text-center forgot-password-register-text">
						Still have a question?
					</div>
					<div className="my-6 rounded-full w-24 forgot-password-heading-separator" />
					<Button
						className="font-primary-bold py-0 px-5 min-w-fit text-lg uppercase grow-0 w-4/12"
						theme="primary"
					>
						<a
							href={links.contact}
							className="py-5 select-none w-full"
							target="_blank"
							rel="noreferrer"
						>
							GET IN TOUCH
						</a>
					</Button>
				</div>
			</div>
		);

		return (
			<div className="flex flex-col lg:flex-row h-screen">
				<div
					className={[
						"flex flex-col grow lg:overflow-y-auto w-full",
						"h-full",
					].join(" ")}
				>
					<div className="flex flex-col my-auto mx-auto px-6 sm:px-0 w-full sm:w-4/5 md:w-9/12 lg:w-3/5 forgot-password-form-body">
						<Wizard
							completeForm={formComplete}
							className="w-full"
							forms={[formStep1]}
							isComplete={props.formSubmitted}
							step={props.step}
						/>
					</div>
				</div>
				{getContactFormContent()}
			</div>
		);
	},
);
