import { commonMessages } from "@app/constants/common-messages";
import type { ValidationErrors } from "@app/constants/validation-errors";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import type { RootState } from "@app/redux";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import "./change-password-modal.css";
import type { FormKey } from "./models/form";
import type { ViewProperties } from "./properties";

export const ChangePasswordModalView = ({
	isSubmitting,
	...props
}: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);

	const getErrors = (error?: string) => {
		const errors = error ? [error] : props.errors || [];

		return (
			<Transition
				show={errors.length > 0}
				enter="transition ease-out duration-300"
				enterFrom="opacity-0 translate-y-5"
			>
				{errors.join(", ")}
			</Transition>
		);
	};

	const getMappedErrorContent = (
		mappedError?: string[],
		localError?: string,
	) => {
		if (!mappedError && !localError) {
			return undefined;
		}

		let errors = [];

		if (localError) {
			if (localError in commonMessages[language].validationError)
				errors.push(
					commonMessages[language].validationError[
						localError as ValidationErrors
					],
				);
			else errors.push(localError);
		}

		if (errors.length === 0 && mappedError) errors = mappedError;

		return (
			<Transition
				show={props.mappedReasons !== undefined || localError !== undefined}
				enter="transition ease-out duration-300"
				enterFrom="opacity-0 translate-y-5"
			>
				<div
					className="api-error"
					dangerouslySetInnerHTML={{
						__html: `<p>${errors.join("</p><p>")}</p>`,
					}}
				/>
			</Transition>
		);
	};

	const getInputField = (options: {
		label: string;
		className: string;
		isInError?: boolean;
		error?: string;
		fieldName: FormKey;
		tooltip?: string;
	}) => {
		const mappedError = getMappedErrorContent(
			props.mappedReasons ? props.mappedReasons[options.fieldName] : undefined,
			options.error,
		);

		return (
			<>
				<label htmlFor={options.fieldName} className="mb-2">
					{options.label}
				</label>
				{mappedError}
				<Input
					className={options.className}
					name={options.fieldName}
					error={
						options.isInError ||
						options.error !== undefined ||
						mappedError !== undefined
					}
					hideIcon
					peekablePassword
					placeholder={options.label}
					required
					theme="secondary"
					title={options.tooltip}
					type="password"
					useFormik
					validateOnChange={props.validate}
					onChange={() => props.onClearErrors(options.fieldName)}
				/>
			</>
		);
	};

	const passwordMismatch = (password?: string, confirmPassword?: string) => {
		return (
			!isEmpty(password) &&
			!isEmpty(confirmPassword) &&
			password !== confirmPassword
		);
	};

	const fieldHasMappedError = (fieldName: string) => {
		let result = false;
		if (!props.mappedReasons || !props.mappedReasons[fieldName]) result = false;
		else
			result =
				props.mappedReasons[fieldName] &&
				props.mappedReasons[fieldName].length > 0;
		return result;
	};

	return (
		<Modal
			className="change-email-modal"
			open={props.open}
			onClose={props.onClose}
		>
			<Formik
				initialValues={{
					newPassword: "",
					confirmPassword: "",
					currentPassword: "",
				}}
				onSubmit={props.onFormSubmit}
			>
				{({ handleSubmit, validateForm, values, errors }) => {
					const passwordsDoNotMatch = passwordMismatch(
						values.newPassword,
						values.confirmPassword,
					);

					return (
						<>
							<h1 className="font-primary-bold mb-3">
								{props.resetPassword ? "Reset password" : "Change password"}
							</h1>
							{getInputField({
								label: "New Password",
								className: "mb-6",
								isInError: fieldHasMappedError("newPassword") && props.validate,
								error: props.validate ? errors.newPassword : undefined,
								fieldName: "newPassword",
								tooltip: "Please enter your new password",
							})}
							{getInputField({
								label: "Password Confirmation",
								fieldName: "confirmPassword",
								className: "mb-6",
								isInError:
									fieldHasMappedError("confirmPassword") ||
									(passwordsDoNotMatch && props.validate),
								error:
									errors.confirmPassword ||
									(passwordsDoNotMatch && props.validate
										? "Password Confirmation must match your new password"
										: undefined),
								tooltip: "Please re-enter your new password",
							})}
							{!props.resetPassword &&
								getInputField({
									label: "Old Password",
									fieldName: "currentPassword",
									className: "mb-6",
									error: errors.currentPassword,
									tooltip: "Please enter your current password",
								})}
							<div className="api-error mb-3">
								{fieldHasMappedError("token") &&
									getErrors(props.mappedReasons!["token"]!.join(" "))}
							</div>
							<Button
								className="font-primary-medium submit"
								theme="primary"
								disabled={isSubmitting}
								onClick={async () => {
									props.onValidate(
										await validateForm(),
										passwordsDoNotMatch,
										handleSubmit,
									);
								}}
							>
								{isSubmitting ? "SUBMITTING" : "SUBMIT"}
							</Button>
						</>
					);
				}}
			</Formik>
		</Modal>
	);
};
