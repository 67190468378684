import { useUpdateUserUsername } from "@app/helpers";
import type { MappedReasons } from "@app/services";
import React from "react";
import { ChangeEmailModalView } from "./change-email-modal-view";
import type { FormValues } from "./models/form";
import type { State } from "./models/state";
import type { Properties } from "./properties";

export const ChangeEmailModal = (props: Properties) => {
	const [updateUserUsername] = useUpdateUserUsername();

	const [state, setState] = React.useState<State>({
		validate: false,
		errors: [],
	});

	const [errors, setErrors] = React.useState<string[] | undefined>(
		props.errors,
	);

	const onClearErrors = (fieldName: string) => {
		var newState = { ...state, errors: [] };

		if (newState.mappedReasons && newState.mappedReasons[fieldName])
			newState.mappedReasons[fieldName] = undefined;

		setState(newState);
	};

	const onFormSubmit = async (values: FormValues) => {
		updateUserUsername(
			values.currentPassword || "",
			values.newUsername || "",
			(errors?: string[] | undefined, mappedReasons?: MappedReasons) => {
				setState({
					...state,
					mappedReasons: mappedReasons,
					validate: true,
				});

				if (!errors && !mappedReasons) {
					props.onClose();
				}
			},
		);
	};

	const onValidate = (
		errors: FormValues,
		customError: boolean | undefined,
		submit: () => void,
	) => {
		if (Object.keys(errors).length > 0 || customError) {
			setState({ ...state, validate: true });
			return;
		}

		submit();
	};

	React.useEffect(() => {
		if (props.errors !== errors) {
			setErrors(props.errors);
		}
	}, [props.errors]);

	React.useEffect(() => {
		if (!props.open) {
			setState({ ...state, validate: false });
		} else {
			setState({ ...state, mappedReasons: undefined });
		}
	}, [props.open]);

	return (
		<ChangeEmailModalView
			{...props}
			errors={errors}
			mappedReasons={state.mappedReasons}
			validate={state.validate}
			onClearErrors={onClearErrors}
			onFormSubmit={onFormSubmit}
			onValidate={onValidate}
		/>
	);
};
