import type { RootState } from "@app/redux";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";

export const useHistorySpread = (period?: number) => {
	const { userIsAuthenticated } = useSelector(
		(rootState: RootState) => rootState.auth,
	);
	const { data, error, mutate, isLoading } = useSWR<
		Array<{
			datetime: string;
			spread: number;
			local_price: number;
			offshore_price: number;
			exchange_rate: number;
		}>
	>(
		period && userIsAuthenticated
			? `/market-conditions/history/?period=${period}`
			: null,
	);

	const historySpread = useMemo(
		() =>
			data
				? data.map((current) => ({
						datetime: current.datetime,
						spread: current.spread,
						localPrice: current.local_price,
						offshorePrice: current.offshore_price,
						exchangeRate: current.exchange_rate,
					}))
				: [],
		[data],
	);
	return {
		historySpread,
		error,
		mutate,
		isLoading,
	};
};
